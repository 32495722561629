.FAQ {
    width: 100%;
    background: #0b1d46;
    margin-bottom: 25px;
    border-radius: 5px
}

.bg-smoke {
    background: #e3e8f2;
    padding: 15px;
    border-radius: 10px;
}

.MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content {
    margin-bottom: 0;
}