.bg-gradient-primary-color {
    background: rgb(0,71,93);
background: linear-gradient(180deg, rgba(0,71,93,1) 0%, rgba(0,166,217,1) 100%);
   
}

.card-1{background: linear-gradient(45deg, #f93a5a, #f7778c) !important}

.card-2{background: linear-gradient(to left, #48d6a8 0%, #029666 100%) !important}

.card-3{background: linear-gradient(to left, #0db2de 0%, #005bea 100%) !important}

.card-4{background: linear-gradient(to left, #efa65f, #f76a2d) !important}

.card {border: 0px !important;box-shadow: -8px 12px 18px 0 #dadee8;}

.text-white{color: #fff;}

.icon-bg {
    background: #00000014;
padding: 14px !important;
border-radius: 5px;
width: 60px;
height: 60px;
text-align: center;
}


.icon-bg i {
    color: #ffffff;
}


.sidebar .nav-item .nav-link span {
    font-size: .85rem;
    display: inline;
    text-transform: uppercase;
}

.sidebar-dark .nav-item .nav-link {
    color: rgba(255, 255, 255);
}

.sidebar-dark .nav-item .nav-link i {
    color: rgba(255, 255, 255);
    margin-right: 5px;
}