.section-box
 {
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ccc;
  
  height: 100%;
 }

 .section-box input[type="radio"]:checked + label {
  border: 2px solid #107df0 !important;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ccc;
 
  height: 100%;
}
.section-box input {
  position: absolute;
  top: 11px;
  left: 22px;
}
.section-box label {
  height: 100%;
  padding: 5px;
}

.formstyle {
  all: unset;
    font-weight: unset;
    font-size: unset;
    padding-top: unset;
    padding-right: unset;
    padding-bottom: unset;
    padding-left: unset;
    width: unset;
  font-weight: 700;
  font-size: 21px !important;
  padding: 0 !important;
}

.description.Formstyle2 {
  all: unset;
    font-weight: unset;
    font-size: unset;
  font-weight: 400;
  font-size: 18px !important;
}
.formstyle3
{
  background: #fff;
  padding: 15px;
  width: fit-content;
  border: 1px solid #cccccc6b;
  border-radius: 2px;
}
.css-gi7hi0-MuiTypography-root {
  margin: 0;
    margin-bottom: 0px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin-bottom: 0 !important;
  color: #353535 !important;
}
/* 
.Section-2 .Delete-1{
  display: none;
} */
.Section-2 .Delete-2{
  display: none;
}
.Section-2 .Delete-4{
  display: none;
}
.Section-2 .Delete-5{
  display: none;
}
/* .Section-2 .update-btn{
  display: none;
} */


.Section-3 .Delete-2, .Section-3 .Delete-5 {
  display: none;
}

.Section-1 .Delete-1, .Section-1 .Delete-2 {
  display: none;
}


.Section-4 .Delete-3, .Section-4 .Delete-4, .Section-4 .Delete-5, .Section-4 .add-btn {
  display: none;
}