a {
    text-decoration: none !important;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item {

    color: #fff !important;

}


.dropdown-menu {

    border: 0px solid rgba(0, 0, 0, .15) !important;

}

.input-search-btn {
    position: absolute;
    top: 0;
    right: 20px;
    background-color: transparent;
    height: 40px;
    width: 40px;
    transition: none;
    font-size: 16px;
    padding-right: 21px;
    color: #b4bdce !important;
    border-radius: 50%;
}

.p-relative {
    position: relative;
}

.main-header-left .form-control {
    height: 40px;
    border-radius: 20px;
    background: #ecf0fa;
    border: 1px solid #ecf0fa;
}

input.form-control::placeholder {
    font-size: 14px;
}

.search-list li {
    background-color: #ecf0fa;
    margin-bottom: 10px;
    border-radius: 5px;
}

.search-list {
    padding: 15px !important;
}

.search-badge {
    color: #fff !important;
    padding: 5px 10px;
    border-radius: 17px;
    font-size: 12px !important;
}

.pull-right {
    float: right;
}

a.search-link {
    text-decoration: none;
}

.search-r-date {
    font-size: 13px !important;
}

.MuiBox-root.css-1c3tbzi {
    display: flex;
    justify-content: space-between;
}

.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px 25px 10px;
    border: 2px solid #ccc;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #00a2d4 !important;
    background: #f8f9fb;
}

ul.react-tabs__tab-list li {
    padding: 6px 25px !important;
}

.react-tabs__tab--selected {
    background: #fff;
    border-color: #03a3d4 !important;
}

.react-tabs {
    background: #fff !important;
    margin: 15px;
}

h1.h3.mb-2.text-gray-800 {
    font-size: 1.4rem;
    padding: 15px 15px 4px;
    text-transform: capitalize;
}

.toggled .sidebar-brand-icon img {
    width: 90Px;
}

.DateRangePickerInput__withBorder {
    border-radius: 5px !important;
    border: 1px solid #dbdbdb !important;
    overflow: hidden !important;
}

.DateInput_input {
    font-weight: 400 !important;
    font-size: 16px !important;
}

.order-row {
    justify-content: space-between !important;
    background: #f8f9fb !important;
    padding: 14px !important;
    margin: 0 !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    background: #f6f7f9 !important;
    border-radius: 5px !important;
    border: 1px solid #ccc !important;
}

button.DateRangePickerInput_clearDates.DateRangePickerInput_clearDates_1.DateRangePickerInput_clearDates_default.DateRangePickerInput_clearDates_default_2 {
    line-height: 0 !important;
}