.css-f2jb3h-MuiPaper-root
{
    box-shadow: none !important;
}

.role-permission-title
{
background: #eef0f4;
  padding: 15px;
  font-size: 19px;
}
.css-6s1xft-MuiGrid-root > .MuiGrid-item {
     padding: 0 !important;
    margin: 10px;
  }
  .css-cveggr-MuiListItemIcon-root {
    min-width: auto !important;
  }
  .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeSmall.MuiButton-outlinedSizeSmall.css-ak74bx-MuiButtonBase-root-MuiButton-root {
    padding: 8px 0 3px !important;
    font-size: 23px !important;
    line-height: 1 !important;
  }
  .rolename
  {
    display: flex;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  margin: 0 auto;
  padding-bottom: 23px;
  gap: 20px;
  }